<template>
  <div id="app">
    <Pageindex class="pageindex" v-if="!isphone" />
    <Phone class="pageindex" v-else />
  </div>
</template>

<script>
import device from "current-device";
import Pageindex from "./components/Page.vue";
import Phone from "./components/Phone.vue";
export default {
  name: "App",
  components: {
    Pageindex,
    Phone,
  },
  data() {
    return {
      isphone: false,
    };
  },
  mounted() {
    let isphone = this._isMobile();
    this.isphone = isphone;

    // WARNING: For GET requests, body is set to null by browsers.

    // let url = `https://jf.oooooohmygosh.com/access/access?type=${
    //   isphone ? "2" : "1"
    // }`;
    // console.log(url);
    // var requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    // };

    // fetch(url, requestOptions)
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) => console.log("error", error));
  },
  methods: {
    //App.vue
    _isMobile() {
      if (device.mobile()) {
        return true;
      }
      if (device.ipad()) {
        if (window.orientation == 0 || window.orientation == 180) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },
};
</script>

<style>
#app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.pageindex {
  width: 100%;
  height: 100%;
}
</style>
