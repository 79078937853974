<template>
  <div class="root">
    <div class="pageclass">
      <div class="left">
        <div class="firststage" ref="firststage">
          <div class="firstwenan" ref="firstwenan">{{ wenanlist[wenanindex] }}</div>
          <div class="Abutton">
            Next tip
            <div class="imgdiv">
              <img src="../img/A_Button.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="nextpage" ref="nextpage">
          <div class="ntitle">Inventory</div>
          <div class="nline" style="margin-top: 4.4vh"></div>
          <div class="booxfoot">
            <div v-for="(item, index) in list" :key="index" class="box" :class="{ hasbox: item != 0 }"
              @dragstart="dragstart" @dragend="dragend" @dragover="dragover($event, index)" @dragenter="dragenter"
              @dragleave="dragleave" @drop="drop(index)" :draggable="item != 0">
              <div class="wuplanimaview tuozhuai" v-if="item != 0">
                <img class="boximg" src="../img/qt8d_icon@2x.png" alt="" />
                <img class="a1111img" src="../img/111111.svg" alt="" />
              </div>
              <div class="wuplanimaview shanshuo" v-else-if="item == 0 && shanshuoindex == index">
                <div class="wuplanimaview">
                  <div class="xline" style="transform: rotate(-45deg)"></div>
                  <div class="xline" style="transform: rotate(45deg)"></div>
                </div>
                <img :draggable="item != 0" class="boximg" src="../img/qt8d_icon@2x.png" alt="" />
              </div>
              <div v-else class="wuplanimaview tuozhuai">
                <div class="xline" style="transform: rotate(-45deg)"></div>
                <div class="xline" style="transform: rotate(45deg)"></div>
              </div>
            </div>
          </div>
          <div class="nline" style="margin-top: 9.53vh"></div>
          <div class="Introduction">
            <div>手写数字的计算器</div>
            <div>Segment display font</div>
          </div>
          <div class="nline" style="margin-top: 0.74vh"></div>
          <div class="detail">
            Sharp QT-8D 计算器，通过特殊的结构设计，<br />
            实现了极其罕见的手写风格段码字。<br />
            这款字体的 0 只能是半高样式，<br />
            这一特征甚至延续到了后来一段时期的荧光管计算器。
          </div>
          <div class="nline" ref="bottomline" style="margin-top: 3.07vh"></div>
        </div>
      </div>
      <div class="right">
        <div class="loadbar" ref="loadbar">
          <div class="loadtext">NOW LOADING…</div>
          <div class="loadwbk">
            <div class="loadnb" ref="loadnb"></div>
          </div>
        </div>
        <canvas class="canvas3d" ref="canvas3d" id="canvas3d"></canvas>
        <div class="xgydclass" ref="xgydclass" @click="clickopenzhihu">
          <div class="xgnline"></div>
          <div class="xginview">
            <img src="../img/Pages.svg" alt="" />
            <div class="xgttt">相关阅读</div>
            <img src="../img/Arrow.svg" alt="" />
          </div>
          <div class="xgnline"></div>
        </div>
      </div>
    </div>
    <div class="drag_img">
      <img ref="drag_img" src="../img/touming.png" />
    </div>
    <div class="bottomview">
      <div @click="clickgongxinbu" class="buttomintext">黑ICP备2021000543号-1</div>
    </div>
  </div>
</template>

<script>
import { Application } from "@splinetool/runtime";
let hsrc = require("../img/zhizhen.svg");
export default {
  name: "PageIndex",
  data() {
    return {
      shanshuoindex: -1,
      loadstate: "loading",
      loadjindu: 0,
      loadBuffer: null,
      list: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      wenanlist: [
        "本网站模型使用 Spline 软件制作，\n这是一款上手即用的 3D 设计软件。\n如果你是设计师，它可以拓宽你的创作可能性。\n",
        "这个网站的道具栏将持续更新，\n你将会根据这些设备了解到许多关于平面设计的知识。\n进去以后，记得点击右下角的「相关阅读」哦！\n",
        "作者非常狡猾，只建一个产品模型并不能撑起一个网站，\n连目录和首页都不知道该怎么做！\n所以把UI做成了游戏道具栏，这样列表里只有一个模型也就不奇怪了！\n",
      ],
      wenanindex: 0,
      wenanchange: false,
      loadcommit: false,
    };
  },
  //监听进度
  watch: {
    loadjindu(val) {
      this.$refs.loadnb.style.width = val + "%";
    },
  },

  created() {
    // 禁用鼠标右键
    document.oncontextmenu = function (e) {
      e.preventDefault();
    };
  },
  mounted() {
    let vm = this;
    document.addEventListener("keydown", this.onKeyDown);
    // 监听键盘
    document.addEventListener("mousedown", this.onMouseDown);

    this.getValue();

    //计算视图绝对位置

    let top = this.$refs.bottomline.getBoundingClientRect().top;
    console.log(top, "top");
    this.$refs.xgydclass.style.top = top - 73.5 + "px";
    //监听窗口大小变化
    window.addEventListener("resize", function () {
      let top = vm.$refs.bottomline.getBoundingClientRect().top;
      vm.$refs.xgydclass.style.top = top - 73.5 + "px";
      console.log(document.documentElement.clientWidth);
      console.log(document.documentElement.clientHeight);
    });
  },
  methods: {
    //加载模型
    async getValue() {
      //使用fetch读取我们写好的接口
      const response = await fetch(
        "https://prod.spline.design/LjuRV9ej7YQaMgJc/scene.splinecode"
      );

      const reader = response.body.getReader();

      // Step 2：获得总长度（length）
      const contentLength = +response.headers.get("Content-Length");

      // Step 3：读取数据
      let receivedLength = 0; // 当前接收到了这么多字节
      let chunks = []; // 接收到的二进制块的数组（包括 body）
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          this.loadjindu = 100;
          this.loadscuuess();
          break;
        }

        chunks.push(value);
        receivedLength += value.length;
        this.loadjindu = (receivedLength / contentLength) * 100;
      }

      // Step 4：将块连接到单个 Uint8Array
      let chunksAll = new Uint8Array(receivedLength); // (4.1)
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position); // (4.2)
        position += chunk.length;
      }
      var foobar = chunksAll.subarray(0, 2);
      var i = foobar.buffer;
      this.loadBuffer = i;
      if (this.loadjindu == 100) {
        this.loadscuuess();
      }
    },
    onMouseDown(e) {
      if (e.button == 0) {
        this.funcwenanchange();
      }
    },
    onKeyDown(event) {
      console.log(event.key);
      if (event.key == "A" || event.key == "a") {
        this.funcwenanchange();
      }
    },
    funcwenanchange() {
      if (this.loadcommit) {
        return;
      }
      this.wenanindex++;
      this.wenanindex = this.wenanindex % this.wenanlist.length;
    },
    loadscuuess() {
      this.loadcommit = true;
      this.$refs.firststage.style.opacity = 0;
      this.$refs.loadbar.style.opacity = 0;
      const canvas = document.getElementById("canvas3d");
      const app = new Application(canvas);
      app.start(this.loadBuffer);

      //延迟2秒执行
      let that = this;
      setTimeout(function () {
        that.$refs.canvas3d.style.opacity = 1;
        that.$refs.nextpage.style.opacity = 1;
        that.$refs.xgydclass.style.opacity = 1;
      }, 2000);
      setTimeout(function () {
        that.$refs.canvas3d.style["transition-duration"] = "0s";
        that.$refs.nextpage.style["transition-duration"] = "0s";
        that.$refs.xgydclass.style["transition-duration"] = "0s";
      }, 4000);
    },
    // 开始拖拽
    dragstart(event) {
      event.dataTransfer.dropEffect = "move";

      console.log(event, "dragstart");
      // event.dataTransfer.setData("text", event.target.id);

      var img = new Image();
      img.src = this.$refs.drag_img.src;
      event.dataTransfer.setDragImage(img, 0, 0);
    },
    // 离开后处理
    dragend(event) {
      let beginselect = -1;
      for (let index = 0; index < this.list.length; index++) {
        const element = this.list[index];
        if (element == 1) {
          beginselect = index;
        }
      }
      if (this.shanshuoindex == beginselect || this.shanshuoindex == -1) {
        this.shanshuoindex = -1;
        return;
      }

      //处理位置
      let arr = [];
      for (let index = 0; index < 16; index++) {
        arr.push(index == this.shanshuoindex ? 1 : 0);
      }
      this.list = arr;
      this.shanshuoindex = -1;
    },
    dragover(event, index) {
      this.shanshuoindex = index;
      event.dataTransfer.dropEffect = "move";
      event.preventDefault();
    },
    dragenter(event) {
      event.dataTransfer.dropEffect = "move";
      // console.log(event, "dragenter");

      event.preventDefault();
    },
    dragleave(event) {
      event.dataTransfer.dropEffect = "move";

      event.preventDefault();
    },
    drop(si) {
      this.shanshuoindex = -1;
      //处理位置
      let arr = [];
      for (let index = 0; index < 16; index++) {
        arr.push(index == si ? 1 : 0);
      }
      this.list = arr;
    },
    clickopenzhihu() {
      window.open("https://www.zhihu.com/question/547937874/answer/2628079858");
    },
    clickgongxinbu() {
      window.open("https://beian.miit.gov.cn");
    }
  },
};
</script>

<style scoped>
.root {
  cursor: url(../img/zhizhen.svg);
  cursor: url(../img/zhizhen.svg), auto;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
  display: flex;
  justify-content: center;
}

.pageclass {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 2500px;
  min-width: 1067px;
  min-height: 600px;
}

/*全局设置移动时候鼠标样式 */

.root:-moz-drag-over {
  cursor: url(../img/zhizhen.svg);
  cursor: url(../img/zhizhen.svg), auto;
}

.left {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.firststage {
  margin-left: 6.458vw;
  width: calc(100% - 6.458vw);
  text-align: left;
  font-size: 1.944vh;
  font-weight: bold;
  letter-spacing: 0px;
  color: #bebeb2;
  opacity: 1;
  line-height: 3.1vh;
  transition-duration: 2s;
  margin-bottom: 18.083vh;
}

.firstwenan {
  white-space: pre-wrap;
  opacity: 1;
  transition-duration: 1s;
}

.right {
  width: 70%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 180;
}

.Abutton {
  display: flex;
  align-items: center;

  margin-top: 41px;
}

.imgdiv {
  margin-left: 10px;
  margin-top: 10px;
}

.loadbar {
  position: absolute;
  bottom: 83px;
  right: 6.458vw;
  transition-duration: 2s;
}

.canvas3d {
  opacity: 0;
  margin-top: -6vh;
  transition-duration: 2s;
}

.loadtext {
  height: 32px;
  text-align: left;
  font-style: italic;
  font-weight: bold;
  font-size: 2.03vh;
  letter-spacing: 0px;
  color: #bebeb2;
  opacity: 1;
}

.loadwbk {
  width: 240px;
  height: 6px;
  margin-top: 15px;
  background: #bebeb277 0% 0% no-repeat padding-box;
  border-radius: 9px;
}

.loadnb {
  width: 0%;
  height: 6px;
  background: #bebeb2 0% 0% no-repeat padding-box;
  border-radius: 9px;
}

.fullput {
  width: 100%;
  height: 100%;
}

.nextpage {
  position: absolute;
  margin-left: 5.1vw;
  width: calc(100% - 5.1vw);
  opacity: 0;
  transition-duration: 2s;
}

.ntitle {
  margin-left: 25px;
  text-align: left;
  font-style: italic;
  font-weight: bold;
  font-size: 4.44vh;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.nline {
  width: 421px;
  height: 0.5px;
  background: transparent linear-gradient(270deg, #000000 0%, #ffffff 52%, #000000 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.booxfoot {
  margin-left: 25px;
  margin-top: 19px;
  display: grid;
  grid-template-columns: repeat(4, 7vh);
  grid-template-rows: repeat(4, 7vh);
  grid-gap: 0.92vh;
}

.box {
  width: 7vh;
  height: 7vh;
  border: 0.5px solid #ffffff;
  opacity: 1;
  position: relative;
}

.box:draggable {
  cursor: url(../img/zhizhen.svg), pointer;
}

.hasbox {
  background: url(../img/weixuanz.svg) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boximg {
  position: absolute;
  height: 4.07vh;
}

.a1111img {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 1.85vh;
}

/*画X */
.xline {
  position: absolute;
  width: 7vh;
  height: 0.5px;
  background-color: #ffffff;
}

.Introduction {
  margin-left: 25px;
  margin-top: 0.5vh;
  text-align: left;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}

.Introduction>div:nth-child(1) {
  text-align: left;
  font-weight: bold;
  font-size: 1.94vh;
  letter-spacing: 0px;
  color: #f0edde;
  opacity: 1;
}

.Introduction>div:nth-child(2) {
  text-align: left;
  font-weight: medium;
  font-size: 1.666vh;
  letter-spacing: 0px;
  color: #94948d;
  opacity: 1;
}

.detail {
  margin-left: 25px;
  margin-top: 0.51vh;
  text-align: left;
  font-weight: medium;
  font-size: 2.03vh;
  letter-spacing: 0px;
  color: #bebeb2;
  opacity: 1;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
}

.xgydclass {
  position: absolute;
  right: 11.48vh;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;
  opacity: 0;
  transition-duration: 2s;
  cursor: pointer;
}

.xgnline {
  width: 31vh;
  height: 0.5px;
  background: transparent linear-gradient(270deg, #000000 0%, #ffffff 52%, #000000 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.xginview {
  height: 6.8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2.03vh;
  color: #bebeb2;
}

.xgttt {
  margin-left: 0.8vh;
  margin-right: 1.94vh;
}

.wuplanimaview {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tuozhuai:hover {
  background: url(../img/xuanzhong.svg) no-repeat center center;
  background-size: cover;
}

.shanshuo {
  -webkit-animation: twinkling 1s infinite ease-in-out;
  animation: twinkling 1s infinite ease-in-out;
  background: url(../img/hong.svg) no-repeat center center;
  background-size: cover;
}

@-webkit-keyframes twinkling {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes twinkling {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.drag_img {
  position: absolute;
  top: -9999px;
  max-width: 100px;
  max-height: 100px;
}

@media screen and (max-height: 600px) {
  .firststage {
    margin-left: 6.458vw;
    width: calc(100% - 6.458vw);
    text-align: left;
    font-size: 11.644px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #bebeb2;
    opacity: 1;
    transition-duration: 2s;
    margin-bottom: 20.083vh;
  }

  .loadtext {
    height: 32px;
    text-align: left;
    font-style: italic;
    font-weight: bold;
    font-size: 12.18px;
    letter-spacing: 0px;
    color: #bebeb2;
    opacity: 1;
  }

  .ntitle {
    margin-left: 25px;
    text-align: left;
    font-style: italic;
    font-weight: bold;
    font-size: 26.64px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .Introduction>div:nth-child(1) {
    text-align: left;
    font-weight: bold;
    font-size: 11.64px;
    letter-spacing: 0px;
    color: #f0edde;
    opacity: 1;
  }

  .Introduction>div:nth-child(2) {
    text-align: left;
    font-weight: medium;
    font-size: 9.96px;
    letter-spacing: 0px;
    color: #94948d;
    opacity: 1;
  }

  .detail {
    margin-left: 25px;
    margin-top: 0.51vh;
    text-align: left;
    font-weight: medium;
    font-size: 12.18px;
    letter-spacing: 0px;
    color: #bebeb2;
    opacity: 1;
    -moz-user-select: none;
    /*火狐*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -ms-user-select: none;
    /*IE10*/
    -khtml-user-select: none;
    /*早期浏览器*/
    user-select: none;
  }

  .xginview {
    height: 6.8vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12.18px;
    color: #bebeb2;
  }
}

.bottomview {
  position: absolute;
  bottom: 6px;
  left: 6px;
  width: 100%;
  font-size: 9px;
  opacity: 1;
  z-index: 9990;
  color: #bebeb2;
  /* 鼠标 移动上去的效果 */
  display: flex;

}

.buttomintext {
  color: #bebeb2;
}

.buttomintext:hover {
  color: white;
  text-decoration: underline;
  /* 这只是一个例子，你可以根据需要更改颜色 */
}
</style>
